import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NewTour = ({ tours, setTours }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [newTourData, setNewTourData] = useState({
    title: "",
    location: "",
    duration: "",
    included: "",
    imageUrl: "",
    description: "",
    recommendation: "",
    unitPrice: "",
    privatePrice: "",
    active: "false",
  });

  const goBacK = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setNewTourData({
      ...newTourData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/tour", JSON.stringify(newTourData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });
      const allTours = [...tours, response.data];
      setTours(allTours);

      setNewTourData({
        title: "",
        location: "",
        duration: "",
        included: "",
        imageUrl: "",
        description: "",
        recommendation: "",
        unitPrice: "",
        privatePrice: "",
        active: "false",
      });
      navigate("/");
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  return (
    <section className="NewTour">
      <div className="mainPanel"> 
      <h3 className="h3Price">New Tour</h3>
      <form className="newTourForm" onSubmit={handleAddFormSubmit}>
        <div className="buttonContainer">
          <label className="hiddenLabel" htmlFor="title">
            Title
          </label>
          <input
            name="title"
            type="text"
            required
            autoComplete="off"
            placeholder="Tour Title"
            value={newTourData.title}
            onChange={handleChange}
          />

          <label className="hiddenLabel" htmlFor="location">
            Location
          </label>
          <input
            name="location"
            type="text"
            required
            autoComplete="off"
            placeholder="Location"
            value={newTourData.location}
            onChange={handleChange}
          />
          <label className="hiddenLabel" htmlFor="imageUrl">
            ImageUrl
          </label>
          <input
            name="imageUrl"
            type="text"
            required
            autoComplete="off"
            placeholder="Image URL"
            value={newTourData.imageUrl}
            onChange={handleChange}
          />
        </div>

        <label className="hiddenLabel" htmlFor="description">
          Description:
        </label>
        <textarea
          name="description"
          required
          autoComplete="off"
          placeholder="Tour Description"
          value={newTourData.description}
          onChange={handleChange}
        />

      <label className="hiddenLabel" htmlFor="included">
          Included:
        </label>
        <input
          name="included"
          type="text"
          required
          autoComplete="off"
          placeholder="Included"
          value={newTourData.included}
          onChange={handleChange}
        />


        <label className="hiddenLabel" htmlFor="recommendation">
          Recommendations:
        </label>
        <textarea
          name="recommendation"
          required
          autoComplete="off"
          placeholder="Recommendations"
          value={newTourData.recommendation}
          onChange={handleChange}
        />

        <div className="buttonContainer">
          <label className="labelSelect" htmlFor="unitPrice">
            Regular Price: $
            <div>
              <input
                name="unitPrice"
                type="number"
                required
                value={newTourData.unitPrice}
                min={1}
                onChange={handleChange}
              />
            </div>
          </label>

          <label className="labelSelect" htmlFor="privatePrice">
            Private Price: $
            <div >
              <input
                name="privatePrice"
                type="number"
                required
                value={newTourData.privatePrice}
                min={1}
                onChange={handleChange}
              />
            </div>
          </label>
          <label className="labelSelect" htmlFor="duration">
            Duration: H
            <div >
              <input
                name="duration"
                type="number"
                required
                value={newTourData.duration}
                min={1}
                onChange={handleChange}
              />
            </div>
          </label>
        </div>

      

        <div className="buttonContainer">
          <button className="saveButton" type="submit">
            Save Tour
          </button>

          <button onClick={goBacK} className="backButton" type="button">
            Cancel{" "}
          </button>
        </div>
      </form></div>
    </section>
  );
};

export default NewTour;
