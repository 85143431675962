import Art from "./Art"

const FeedArt = ({ arts }) => {
  return (
    <>
    {arts.map(art=>(
      <Art key={art._id} tour={art}/>
    ))}
    </>

  )
}

export default FeedArt;