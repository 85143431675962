import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";
import useWindowSize from "../hooks/useWindowSize";

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(true);
  const { width } = useWindowSize();
  const location = useLocation().pathname;
  const logout = useLogout();
  const { auth } = useAuth();
  const toggleMenu = () => {
    setOpenMenu((openMenu) => !openMenu);
  };

  const signOut = async () => {
    await logout();
  };
  useEffect(() => {
    if (width <= 650) setOpenMenu(false);
    if (width > 650) setOpenMenu(true);
  }, [width, setOpenMenu, location]);

  return (
    <nav className="Nav">
      <div className="navHeader">
        <div className="menuButton" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
      {openMenu && (
        <div className="navUl">
          <ul>
            {location !== "/" && (
              <>
                <Link to="/">
                  <li>Tours</li>
                </Link>
              </>
            )}

            {auth.username && (
              <>
                {location !== "/art_gallery" && (
                  <>
                    <Link to="/art_gallery">
                      <li>Art Gallery</li>
                    </Link>
                  </>
                )}
                
                {location !== "/dashboard" && (
                  <>
                    <Link to="/dashboard">
                      <li>Dashboard</li>
                    </Link>
                  </>
                )}

                {location !== "/tour/new" && (
                  <>
                    <Link to="/tour/new">
                      <li>New Tour</li>
                    </Link>
                  </>
                )}
                {location !== "/art/new" && (
                  <>
                    <Link to="/art/new">
                      <li>New Art</li>
                    </Link>
                  </>
                )}
                {location !== "/reservations" && (
                  <>
                    <Link to="/reservations">
                      <li>Reservations</li>
                    </Link>
                  </>
                )}
                {location !== "/user" && (
                  <>
                    <Link to="/user">
                      <li>User</li>
                    </Link>
                  </>
                )}
              </>
            )}

            {auth.username && (
              <>
                <li onClick={() => signOut()}>Log Out</li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Nav;
