import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NewArt = ({ arts, setArts }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [newArtData, setNewArtData] = useState({
    title: "",
    imageUrl: "",
    active: "false",
  });

  const goBacK = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setNewArtData({
      ...newArtData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/art", JSON.stringify(newArtData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      });
      const allArts = [...arts, response.data];
      setArts(allArts);

      setNewArtData({
        title: "",
        imageUrl: "",
        active: "false",
      });
      navigate("/");
    } catch (err) {
      console.log(`Error occurred while new art creation: ${err.message}`);
    }
  };

  return (
    <section className="NewTour">
      <div className="mainPanel">
        <h3 className="h3Price">New Art</h3>
        <form className="newTourForm" onSubmit={handleAddFormSubmit}>
          <div className="buttonContainer">
            <label className="hiddenLabel" htmlFor="title">
              Title
            </label>
            <input
              name="title"
              type="text"
              required
              autoComplete="off"
              placeholder="Art Title"
              value={newArtData.title}
              onChange={handleChange}
            />

            <label className="hiddenLabel" htmlFor="imageUrl">
              Image Url
            </label>
            <input
              name="imageUrl"
              type="text"
              required
              autoComplete="off"
              placeholder="Image URL"
              value={newArtData.imageUrl}
              onChange={handleChange}
            />
          </div>

          <div className="buttonContainer">
            <button className="saveButton" type="submit">
              Save Art
            </button>

            <button onClick={goBacK} className="backButton" type="button">
              Cancel{" "}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default NewArt;
