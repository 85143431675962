import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Art = ({ art }) => {
  const { auth } = useAuth();
  return (
    <>
      {art && art.active === "true" && (
        <>
          <article className="art">
            <Link to={`/art/${art._id}`}>
              <div className="tourInfo">
                <h3>{art.title}</h3> 
               </div>
              <div className="tourImage">
                <img src={art.imageUrl} alt="img" loading="lazy" />
              </div>
            </Link>
          </article>
        </>
      )}
      {auth.username && art && art.active === "false" && (
        <>
          <br />
          <article className="tour">
            <Link to={`/art/${art._id}`}>
              <div className="tourInfo">
                <h2 className="Inactive">Inactive Art: {art.title}</h2>
                <br />
              </div>
              <div className="tourImage">
                <img src={art.imageUrl} alt="img" loading="lazy"/>
              </div>
            </Link>
          </article>
        </>
      )}
      {!art && (
        <>
          <h2>Art not found</h2>
        </>
      )}
    </>
  );
};

export default Art;
