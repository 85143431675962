import FeedArt from "../components/FeedArt";
import useAxiosFetch from "../hooks/useAxiosFetch";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const ArtGallery = () => {
  const dataUrl = `${process.env.REACT_APP_SERVER}/arts`;
  const [arts, setArts] = useState([]);
  const navigate = useNavigate();
  const { data } = useAxiosFetch(dataUrl);
  const goBack = () => {
    navigate("/", {replace: "true"});
  };

  useEffect(() => {
    setArts(data);
  }, [data]);
  return (
    <>
      {arts && (
        <section className="Home">
          <br />
          <h2 className="h2Home">Page under Construction</h2>
          <div className="Unauthorized">

          {(arts.length ? (
            
         <FeedArt arts={arts} />
        ) : (
          <>       
           <h2 className="h2Home" >No art to show yet</h2>

        <button type="button" className="backButton" onClick={goBack} >Go Back</button>
        </>
      

        ))}
        

          
          </div>
        </section>
      )}
    </>
  );
};

export default ArtGallery;
